import React, { useRef, type FC, type PropsWithChildren } from 'react';
import classnames from 'classnames';
import { ILLUSTRATIONS } from 'bb/campaigns/config';
import { NumberOfBooks, Section } from 'bb/common';
import { StartAtPrice } from 'bb/common/components';
import { UspCol, UspContainer, UspItem } from 'bb/common/components/usp';
import { Grid } from 'bb/common/grid/Grid';
import { Image } from 'bb/common/images';
import { SM_100VW_LG_50VW } from 'bb/common/images/config';
import { type ImageData } from 'bb/common/images/types';
import css from 'bb/discovery/start/start.module.scss';
import commonCss from 'bb/style/common.module.scss';
import { type Color } from 'bb/style/types';
import { Col } from 'bb/ui';
import { useResizeObserver } from 'bb/ui/hooks';

type StartUspsProps = {
    image?: ImageData;
    bgColor?: Color;
};

const MAX_HEIGHT = 600;

export const StartUsps: FC<PropsWithChildren<StartUspsProps>> = ({
    children,
    image,
    bgColor = 'tertiary-yellow'
}) => {
    const paddedBoxRef = useRef<HTMLDivElement>(null);
    const uspColumnRef = useRef<HTMLDivElement>(null);
    const imageColumnRef = useRef<HTMLDivElement>(null);

    useResizeObserver(paddedBoxRef, ([entry]) => {
        if (!entry) return;

        const entryTarget = entry.target as HTMLElement;

        const hasOverflow = entryTarget.scrollHeight > MAX_HEIGHT;

        [imageColumnRef.current, uspColumnRef.current].forEach((element) => {
            if (element) {
                element.style.maxHeight = hasOverflow
                    ? 'none'
                    : `${MAX_HEIGHT}px`;
            }
        });
    });

    return (
        <Section data-optimizely="start-usp-section">
            <Grid>
                <UspContainer
                    style={
                        {
                            '--col-max-height': `${MAX_HEIGHT}px`
                        } as React.CSSProperties
                    }
                >
                    <UspCol
                        ref={uspColumnRef}
                        paddedBoxRef={paddedBoxRef}
                        bgColor={bgColor}
                        className={css.colItem}
                        data-optimizely="usp-column"
                    >
                        {/* TODO: Make more flexible and remove default children */}
                        {children || (
                            <>
                                <UspItem
                                    header="start:gradeHeader"
                                    text="start:gradeText"
                                    textValues={<StartAtPrice />}
                                    data-optimizely="usp-item-1"
                                />
                                <UspItem
                                    marginTop="medium"
                                    header="start:booksHeader"
                                    text="start:booksText"
                                    headerValues={<NumberOfBooks />}
                                    data-optimizely="usp-item-2"
                                />
                                <UspItem
                                    marginTop="medium"
                                    header="start:noTermsHeader"
                                    text="start:noTermsText"
                                    data-optimizely="usp-item-3"
                                />
                            </>
                        )}
                    </UspCol>
                    <Col
                        ref={imageColumnRef}
                        className={classnames(css.colItem, commonCss.col50)}
                        data-optimizely="start-usp-image"
                    >
                        <Image
                            img={
                                image?.src
                                    ? image
                                    : ILLUSTRATIONS[
                                          bgColor as keyof typeof ILLUSTRATIONS
                                      ]
                            }
                            config={SM_100VW_LG_50VW}
                            className={css.subHeroImage}
                        />
                    </Col>
                </UspContainer>
            </Grid>
        </Section>
    );
};
